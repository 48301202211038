import React from 'react';
import './Skill.css';

const Skill = ({ image, title }) => {
    return (
        <div className="portfolio__skills-container__skill">
            <div className="portfolio__skills-container__skill-title">
                <div />
                <h1>{title}</h1>
                <div>{image}</div>
            </div>
        </div>
    );
};

export default Skill;