import React from "react";
import "./Footer.css";
import logo from "../../assets/NewLogo.svg";

const Footer = () => {
  return (
    <div className="portfolio__footer section__padding" id="contact">
      <div className="portfolio__footer-heading">
        <h1 className="gradient__text">Let's work together.</h1>
      </div>
      <div className="portfolio__footer-links">
        <div className="portfolio__footer-links_logo">
          <img src={logo} alt="logo" />
          <p>Code. Design. Have Fun.</p>
        </div>
        <div className="portfolio__footer-links_div">
          <h4>Links</h4>
          <a
            href="https://github.com/jianganna7"
            target="_blank"
            rel="noreferrer"
          >
            Github
          </a>
          <a
            href="https://www.linkedin.com/in/anna-jiang-dev/"
            target="_blank"
            rel="noreferrer"
          >
            Linkedin
          </a>
        </div>
        <div className="portfolio__footer-links_div">
          <h4>Social</h4>
          <a href="/">Discord</a>
        </div>
        <div className="portfolio__footer-links_div">
          <h4>Email</h4>
          <a href="mailto:annajiang1777@gmail.com" rel="noreferrer">
            annajiang1777@gmail.com
          </a>
        </div>
      </div>
      <div className="portfolio__footer-copyright">
        <p>© 2025 | Designed & coded by Anna Jiang. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
