import React from 'react';
import './Project.css';

const Project = ({ imgURL, date="_", title="Upcoming", skillsData, type="Commercial" }) => {
    return (
        <div className="portfolio__projects-container_project">
            <div className="portfolio__projects-container_project-image">
                <img src={imgURL} alt="project"/>
            </div>
            <div className="portfolio__projects-container_project-content">
                <div className="portfolio__projects-container_project-content_header">
                    <div className="portfolio__projects-container_project-content_header-date">
                        <p>{date}</p>
                        <p className="gradient__text">{type}</p>
                    </div>
                    <h3>{title}</h3>
                </div>
                <div className="portfolio__projects-container_project-content_icons">
                    { skillsData && skillsData.map((item) => (
                        <div>{item}</div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Project;