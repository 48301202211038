import React from 'react';
import './Header.css';
import home from '../../assets/home.png';

const Header = () => {
    return (
        <div className="portfolio__header section__padding" id="home">
            <div className="portfolio__header-content">
                <p>Hi, I'm Anna.</p>
                <h1 className="gradient__text">A Designer turned Developer.</h1>
                <p>Passionate about bringing both the technical and visual aspects of digital products to life. </p>
            </div>
            <div className="portfolio__header-image">
                <img src={home} alt="home" />
            </div>
        </div>
    );
};

export default Header;