import React from 'react';

import { Footer, Blog, Possibility, Projects, About, Header, Skills } from "./containers";
import { Brand, CTA, Navbar } from "./components";
import './App.css';

//rsc hot key to get functional component snippet
const App = () => {
    return (
        <div className="App">
            <div className="gradient__bg">
                <Navbar />
                <Header />
            </div>
            <About />
            <Skills />
            <CTA />
            <Projects />
            <Blog />
            <Footer />
        </div>
    );
};

export default App;