import React, {useState} from 'react';
import './CTA.css';

const CTA = () => {
    const [hoverButton, setHoverButton] = useState(false);
    
    return (
        <div className="portfolio__cta">
            <div className="portfolio__cta-content">
                <p>Thank you so much for reviewing my Portfolio.</p>
                <h3>Interested in learning more about my work or collaborate on an upcoming project?</h3>
            </div>
            <div className="portfolio__cta-btn">
                {hoverButton
                    ? <a href="#contact" className="heartbeat" onMouseLeave={() => setHoverButton(false)}>Reach out today</a>
                    : <a href="#contact" onMouseOver={() => setHoverButton(true)}>Reach out today</a>
                }
            </div>
        </div>
    );
};

export default CTA;