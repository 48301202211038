import React from "react";
import { Skill } from "../../components";
import {
  FaGithub,
  FaUnity,
  SiJavascript,
  FaReact,
  FaBootstrap,
  FaCss3,
  FaHtml5,
  SiCsharp,
  SiXamarin,
  SiMysql,
  SiAdobephotoshop,
  SiAdobeillustrator,
  MdSettingsSuggest,
  SiTypescript,
  SiAdobexd,
  SiLaravel,
  SiPhp,
  SiWordpress,
  FaElementor,
  SiTailwindcss,
  SiSqlite,
  SiAmazonaws,
  SiKubernetes,
  SiSplunk,
  SiMui,
  SiNodedotjs,
  SiNewrelic,
  SiAdyen,
  SiSnyk,
  SiJira,
} from "./imports";
import "./Skills.css";
const skillsData = [
  // Frontend Development
  { title: "HTML5", img: <FaHtml5 /> },
  { title: "CSS", img: <FaCss3 /> },
  { title: "JavaScript", img: <SiJavascript /> },
  { title: "TypeScript", img: <SiTypescript /> },
  { title: "React", img: <FaReact /> },
  { title: "BootStrap", img: <FaBootstrap /> },
  { title: "TailWind", img: <SiTailwindcss /> },
  { title: "Material UI", img: <SiMui /> },

  // Backend Development
  { title: "Node.js", img: <SiNodedotjs /> },
  { title: "Laravel", img: <SiLaravel /> },
  { title: "PHP", img: <SiPhp /> },
  { title: "MySQL", img: <SiMysql /> },
  { title: "Sqlite", img: <SiSqlite /> },
  { title: "RESTful APIs", img: <MdSettingsSuggest /> },
  { title: "C#", img: <SiCsharp /> },

  // DevOps / Cloud
  { title: "AWS", img: <SiAmazonaws /> },
  { title: "K8s", img: <SiKubernetes /> },

  // Monitoring and Analytics
  { title: "Splunk", img: <SiSplunk /> },
  { title: "New Relic", img: <SiNewrelic /> },
  { title: "Snyk", img: <SiSnyk /> },

  // Version Control and Collaboration
  { title: "Github", img: <FaGithub /> },
  { title: "Unity", img: <FaUnity /> },

  // Other
  { title: "Xamarin", img: <SiXamarin /> },
  { title: "Adyen", img: <SiAdyen /> },

  // CMS and Web Development Tools
  { title: "WordPress", img: <SiWordpress /> },
  { title: "Elementor", img: <FaElementor /> },

  // Design Tools
  { title: "Adobe XD", img: <SiAdobexd /> },
  { title: "Illustrator", img: <SiAdobeillustrator /> },
  { title: "Photoshop", img: <SiAdobephotoshop /> },

  // Project Management
  { title: "Jira", img: <SiJira /> },
];

const Skills = () => {
  return (
    <div className="portfolio__skills section__padding" id="skills">
      <div className="portfolio__skills-heading">
        <h1 className="gradient__text">Skills & Tools</h1>
      </div>
      <div className="portfolio__skills-container">
        {skillsData.map((item, index) => (
          <Skill image={item.img} title={item.title} key={item.title + index} />
        ))}
      </div>
    </div>
  );
};

export default Skills;
