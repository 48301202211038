import React from 'react';
import './Feature.css';

const Feature = ({ title, text }) => {
    //Text segments that will get reordered into new paragraphs if specified
    const segments = text.split("</n>");
    return (
        <div className="portfolio__features-container__feature">
            <div className="portfolio__features-container__feature-title">
                <div />
                <h1>{title}</h1>
            </div>
            <div className="portfolio__features-container__feature-text">
                {segments.map((seg) => (
                    <p>{seg}</p>
                ))}
            </div>
        </div>
    );
};

export default Feature;