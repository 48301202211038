import React, { useState } from "react";
import "./About.css";
import { Feature } from "../../components";

const About = () => {
  const [hoverButton, setHoverButton] = useState(false);

  return (
    <div className="portfolio__about section__margin" id="about">
      <div className="portfolio__about-feature">
        <Feature
          title="Who is Anna?"
          text="Anna is a Full Stack developer based in Australia who enjoys turning ideas into digital experiences that are both visually appealing and highly functional. With a Bachelor's in Digital Media and a Master's in Information Technology, she has found a fulfilling space where design meets development. Anna enjoys blending the creativity of a graphic designer with the technical expertise of a developer, working to create solutions that feel both impactful and meaningful.
                    </n>Her experience spans a variety of projects, including mobile apps, websites, branding, and larger-scale systems. She approaches each project with curiosity, care, and a commitment to delivering something fresh and inspiring. Her guiding philosophy? Create work that truly resonates with users and leaves a lasting impression.
                    </n>Outside of her professional work, Anna channels her creativity into game design and development. In her free time, she works on visual novel games, taking part in everything from writing the story and designing characters to coding. It's a passion that keeps her skills sharp and her imagination thriving.
                    </n>Anna is always eager to learn, grow, and collaborate with others. She enjoys connecting with like-minded people who are passionate about bringing ideas to life and creating something truly special together."
        />
      </div>
      <div className="portfolio__about-heading">
        <h1 className="gradient__text">
          The scope of her experience and explorations cover...
        </h1>
        {hoverButton ? (
          <a
            href="#projects"
            className="text-shadow-pop-top"
            onMouseLeave={() => setHoverButton(false)}
          >
            Explore her works
          </a>
        ) : (
          <a href="#projects" onMouseOver={() => setHoverButton(true)}>
            Explore her works
          </a>
        )}
      </div>
      <div className="portfolio__about-container">
        <Feature
          title="Mobile App"
          text="Deliver mobile applications for both iOS and Android operating systems with seamless navigation and beautiful design. 
                    Expertise in cross-platform development using Xamarin.Forms and C#."
        />
        <Feature
          title="Web Development"
          text="Build simple, responsive and intuitive web solutions to meet business needs, with user experience in mind. 
                    Exposure to a variety of web technologies, with strong interest in React."
        />
        <Feature
          title="Game Development"
          text="Unleash creative vision to develop fun and interactive games to cater to anyone. 
                    Fluent in 2D game development using Unity and C#."
        />
      </div>
    </div>
  );
};

export default About;
