import React from 'react';
import {Project} from "../../components";
import { project1, project2, project3, project4, project5 } from './imports';
import { FaGithub, SiJavascript, FaReact, FaBootstrap, FaCss3, SiCsharp, SiXamarin, SiAdobephotoshop, SiAdobeillustrator, MdSettingsSuggest, SiTypescript, SiAdobexd, SiLaravel, SiPhp, SiWordpress, FaElementor, SiTailwindcss, SiSqlite } from "../skills/imports";
import './Projects.css';

const Projects = () => {
    return (
        <div className="portfolio__projects section__padding" id="projects">
            <div className="portfolio__projects-heading">
                <h1 className="gradient__text">Get a peek at some of the past projects.</h1>
            </div>
            <div className="portfolio__projects-container">
                <div className="portfolio__projects-container_groupA">
                    <Project
                        imgURL={project3}
                        date="In beta testing"
                        title="A self-contained screening tool, Recognise SLP(Speech Language Pathologist), designed and developed for Griffith's School of Allied Health Sciences
                         – Speech Pathology. Available in both iOS and Android."
                        skillsData={[<SiXamarin />, <SiCsharp />, <FaGithub />]}/>
                </div>
                <div className="portfolio__projects-container_groupB">
                    <Project 
                        imgURL={project4} 
                        date="Jan 15, 2022" 
                        title="The frontend for an Affordability Check platform collaboratively built with Alloc8te's development team for Alloc8te, a company focuses on simplify personal finance."
                        skillsData={[<FaReact />, <SiTypescript />, <SiTailwindcss />, <FaGithub />]}/>
                    <Project
                        imgURL={project1}
                        date="Apr 18, 2021"
                        title="An Adobe XD prototype built for MedMagLabs, an Australian company that creates wound-care treatment systems 
                        for people in humanitarian emergencies using maggot therapy."
                        skillsData={[<SiAdobexd />, <SiAdobephotoshop />, <SiAdobeillustrator />]}/>
                    <Project
                        imgURL={project2}
                        date="Aug 26, 2021"
                        title="The official website designed and developed for the App Factory, a student-led organisation founded at Griffith University, that creates digital solutions 
                        for businesses."
                        skillsData={[<SiWordpress/>, <FaElementor />, <FaCss3 />, <SiJavascript />, <FaGithub />]}/>
                    <Project
                        imgURL={project5}
                        date="June 28, 2020"
                        title="A review application that stores and display user reviews for confectionaries. Authentication and user access control is in place for determination of CRUD operations to be carried out on products as well as food items."
                        skillsData={[<SiLaravel />, <SiSqlite />, <SiPhp />, <FaBootstrap />, <MdSettingsSuggest />, <FaGithub />]}
                        type="Uni Project"/>
                </div>
            </div>
        </div>
    );
};

export default Projects;